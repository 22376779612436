import { render, staticRenderFns } from "./authorizationManagement.vue?vue&type=template&id=41ff6604&scoped=true&"
import script from "./authorizationManagement.vue?vue&type=script&lang=js&"
export * from "./authorizationManagement.vue?vue&type=script&lang=js&"
import style0 from "./authorizationManagement.vue?vue&type=style&index=0&id=41ff6604&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ff6604",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1551980-cci-50566653-917405/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41ff6604')) {
      api.createRecord('41ff6604', component.options)
    } else {
      api.reload('41ff6604', component.options)
    }
    module.hot.accept("./authorizationManagement.vue?vue&type=template&id=41ff6604&scoped=true&", function () {
      api.rerender('41ff6604', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectResourse/systemSettings/authorizationManagement/authorizationManagement.vue"
export default component.exports