<template>
  <div class="authorization-management" ref="authorizationManagement">
    <div class="left">
      <p class="search">
        <el-input
          placeholder="角色名称"
          v-model.trim="keyword"
          class="input-with-select"
        >
          <el-button
            slot="append"
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="searchUser"
          ></el-button>
        </el-input>
      </p>
      <div class="user-list">
        <ul ref="userList">
          <li
            v-for="(user, index) in userList"
            :class="{ active: index == 0 }"
            :key="user.id"
            @click="onUserClick(user, $event)"
          >
            {{ user.roleName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <p class="title">
        <span>操作权限列表</span>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
          :loading="editLoading"
          v-if="$store.state.menu.nowMenuList.indexOf('权限配置') >= 0"
          >{{ isEditVisible ? '保存' : '权限配置' }}</el-button
        >
      </p>
      <div ref="systemList" class="system-list">
        <a
          v-for="(auth, key) in tableData"
          :key="key"
          href="javascript:void(0)"
          :class="{ active: key == 0 }"
          @click="onSystemClick(auth.id, $event)"
        >
          {{ auth.menuName }}</a
        >
      </div>
      <div class="table-box">
        <auth-table
          ref="authTable"
          v-for="auth in tableData"
          :key="auth.id"
          :id="'table' + auth.id"
          :data="auth.subMenuTrees"
          :menuName="auth.menuName"
          :tableHeight="tableHeight"
          :isEditVisible="isEditVisible"
          @getMenuIds="getMenuIds"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRoleLabelList,
  getAuthorizationMenu,
  updateAuthorizationMenu,
  queryRoleList,
  getMenus
} from '@/api/lib/api.js'
import authTable from './components/table.vue'

export default {
  name: 'authorizationManagement',
  components: {
    authTable
  },
  data() {
    return {
      keyword: '',
      userList: [],
      spanArr: [],
      tableData: [],
      isEditVisible: false,
      tableHeight: 0,
      currentUser: null,
      editLoading: false,
      activeName: '',
      menuIds: []
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.authorizationManagement.clientHeight
      this.tableHeight = wholeHeight - 32 - 32 - 16 - 80
    },
    // 选取用户
    onUserClick(user, e) {
      Array.from(this.$refs.userList.children).forEach((el) => {
        el.classList.remove('active')
      })
      e.target.classList.add('active')
      this.currentUser = user
      this.isEditVisible = false
      this.getAuthorizationList()
    },
    // 选取系统
    onSystemClick(id, e) {
      Array.from(this.$refs.systemList.children).forEach((el) => {
        el.classList.remove('active')
      })
      e.target.classList.add('active')
      document
        .getElementById(`table${id}`)
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    // 保存
    onSave() {
      if (!this.isEditVisible) {
        this.isEditVisible = true
      } else {
        this.getCheckedNodes()
        const r = this.menuIds.filter(s=>s);//数组去空元素
        // let list = [...new Set(this.menuIds)]
        let list = [...new Set(r)]
        this.editLoading = true
        const data = {
          roleId: this.currentUser.id,
          menuIds: list
        }
        updateAuthorizationMenu({ ...data }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('修改成功')
            // this.getAllMenu()
            this.getAuthorizationList()
          } else {
            this.$message.error(res.msg)
          }
          this.editLoading = false
        })
        this.isEditVisible = false
      }
    },
    getAllMenu() {
      // getMenus({}).then((res) => {
      //   if (res.code === 1000) {
      //     this.$store.dispatch('add_Menus', res.data)
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },

    // 获取用户名列表
    getRoleList() {
      const arr = []
      getRoleLabelList({}).then((res) => {
        if (res.code === 1000) {
          res.data.forEach((item) => {
            if (item.status === 0) {
              arr.push(item)
            }
          })
          this.userList = arr
          this.currentUser = this.userList[0]
          this.getAuthorizationList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取权限列表
    getAuthorizationList() {
      getAuthorizationMenu({ roleId: this.currentUser.id }).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.subMenuTrees
          this.menuIds = Array.from(this.tableData, (item) => item.id)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取选中权限id
    getCheckedNodes() {
      this.$refs.authTable.forEach((item) => {
        item.getCheckedNodes()
      })
    },
    // 获取选中权限id
    getMenuIds(menuIds) {
      this.menuIds = this.menuIds.concat(menuIds)
    },
    searchUser() {
      queryRoleList({
        roleName: this.keyword,
        status: 0,
        currentPage: 1,
        pageSize: 100
      }).then((res) => {
        if (res.code === 1000) {
          this.userList = res.data.list
          this.currentUser = this.userList[0]
          this.getAuthorizationList()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  created() {
    this.getRoleList()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.authorization-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  background-color: #fff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    .user-list li.active,
  .user-list ul li:hover {
    background-color: rgba($color: #059370, $alpha: 0.1);
    color: #059370;
  }
  // overflow: auto;
  @include themify() {
    .left {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      width: 187px;
      .search {
        margin: 0 0 16px;
      }
      .user-list {
        flex: 1;
        background-color: themed('n1');
        overflow-y: auto;
        & > ul {
          font-size: 14px;
          line-height: 38px;
          height: 100%;
          & > li {
            padding: 0 16px;
            transition: all 0.2s linear;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      width: calc(100% - 207px);

      .title {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 16px;
        padding: 0;
        & > span {
          color: themed('n7');
          font-size: 16px;
        }
      }
      .system-list {
        display: flex;
        flex-wrap: nowrap;
        height: 40px;
        border-bottom: 2px solid #e4e7ed;

        & > a {
          padding: 0 10px;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          cursor: pointer;
          color: themed('b');
          text-decoration: none;
        }
        a:nth-child(1) {
          padding-left: 0;
        }
        a.active {
          border-bottom: 1px solid;
          border-color: themed('b4');
          color: themed('b4');
          z-index: 1;
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          list-style: none;
        }
        a:hover {
          color: themed('b4');
        }
      }
      .table-box {
        height: 89%;
        overflow-y: auto;
      }
    }
  }
}

</style>
